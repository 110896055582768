import React, { useEffect, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Table, Button, message, Spin } from 'antd';
import { getBackups } from '../../services/backup';
import axios from 'axios';
import { useSelector } from 'react-redux';

const BackupList = () => {
const { currentUser } = useSelector((state) => state.user);
  const [backups, setBackups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);

  useEffect(() => {
    const fetchBackups = async () => {
      try {
        const backupData = await getBackups();
        setBackups(backupData.data);
        setFetchError(false);
      } catch (error) {
        message.error('Failed to fetch backups');
        setFetchError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchBackups();
  }, []);

  const columns = [
    {
      title: 'Download',
      dataIndex: 'path',
      key: 'path',
      render: (path, record) => (
        <Button
          onClick={() => handleDownload(record.path)}
          type="primary"
          icon={<DownloadOutlined />}
          disabled={!path}
        >
          Download
        </Button>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
  ];

  const handleDownload = async (downloadUrl) => {
    try {
      const response = await axios.get(downloadUrl, {
        responseType: 'blob', // Important: specify responseType as blob
        headers: {
          Authorization: `Bearer ${currentUser.token}`, // Include the token in the headers
        },
      });

      const fileName = downloadUrl.split('/').pop(); // Extract filename from URL
      const blob = new Blob([response.data], { type: 'application/zip' }); // or the correct MIME type

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', fileName); // Set the filename for download
      document.body.appendChild(link);
      link.click();
      link.remove(); // Clean up the link
      URL.revokeObjectURL(link.href); // Release the object URL

    } catch (error) {
      console.error('Error downloading file:', error);
      message.error('Failed to download backup');
    }
  };


  return (
    <div>
      <Spin tip="Loading backups..." spinning={loading}>
        {!loading && !fetchError && (
          <Table
            columns={columns}
            dataSource={backups}
            rowKey={(record) => record.path} // Use a unique key
          />
        )}
        {fetchError && <div>Failed to load backups. Please try again later.</div>}
      </Spin>
    </div>
  );
};

export default BackupList;